import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import {t} from 'i18next';
import { Link } from 'react-router-dom'
import user from '../../assets/images/1_old.png'
import { isNull } from '../../izUtils';
import './LeftSide.css';

const LeftSide = ({type, logout}) => {
    const userState = useSelector((state) => state.user);
    const sidebarNav = useRef(null);
    const [isProvider, setIsProvider] = useState(false);

    useEffect(() => {
        let isProv = false;
        if (!isNull(userState.parsedToken)) {
            if (!isNull(userState.parsedToken.company)) {
                userState.parsedToken.company.forEach(element => {
                    if (element.provider === 1) {
                        isProv = true;
                    }
                });
            }
        }
        setIsProvider(isProv);

        if (!isNull(sidebarNav.current)) {
            let childs = sidebarNav.current.childNodes
            childs.forEach(item => {
                item.classList.remove("active")
            })
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState.parsedToken])

    const handleClick = (e, key) => {
        const element = document.getElementById(key);

        let attr = element.getAttribute("aria-expanded");
        if(attr === 'true') {
            attr = 'false'
        } else {
            attr = 'true'
        }

        element.setAttribute('aria-expanded', attr)
        element.parentElement.classList.toggle("active")
        element.nextElementSibling.classList.toggle("in");
    }

    return (
        <aside className="left-sidebar">
            <div className="scroll-sidebar leftSide-scroll">
                <div className="user-profile user-background">
                    <div className="profile-img">
                        <img src={ user } alt="user"/>
                    </div>
                </div>
                <nav className="sidebar-nav">
                    <ul id="sidebarnav" ref={sidebarNav}>
                        {type === "public" ? 
                            <>
                                <li className="nav-small-cap">{t('leftSide.public.reference')}</li>
                                <li><Link className="has-arrow waves-effect waves-dark" to={'/javne-reference'} aria-expanded="false"><i><span className="iconify" data-icon="mdi-bulletin-board" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.public.publicReferences')}</span></Link></li>
                                <li><Link className="has-arrow waves-effect waves-dark" to={'/napredno-iskanje/javno'} aria-expanded="false"><i><span className="iconify" data-icon="mdi-layers-search-outline" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.public.advancedSearch')}</span></Link></li>
                                <li className="nav-small-cap">{t('leftSide.public.users')}</li>
                                <li><Link className="has-arrow waves-effect waves-dark" to={'/login'} aria-expanded="false"><i><span className="iconify" data-icon="mdi-account-outline" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.public.login')}</span></Link></li>
                                <li><Link className="has-arrow waves-effect waves-dark" to={'/register'} aria-expanded="false"><i><span className="iconify" data-icon="mdi-pencil-box-outline" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.public.register')}</span></Link></li>
                                <li className="nav-small-cap">{t('leftSide.public.info')}</li>
                                <li><Link className="has-arrow waves-effect waves-dark" to={'/splosni-pogoji'} aria-expanded="false"><i><span className="iconify" data-icon="mdi-routes" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.public.termsConditions')}</span></Link></li>
                                <li><Link className="has-arrow waves-effect waves-dark" to={'/cenik'} aria-expanded="false"><i><span className="iconify" data-icon="mdi-currency-eur" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.public.priceList')}</span></Link></li>
                            </>
                            :
                            (!isProvider ?
                                <>
                                    <li>
                                        <button onClick={(e) => {handleClick(e,'packages')}} id="packages" className="has-arrow waves-effect waves-dark" aria-expanded="false"><i><span className="iconify" data-icon="mdi-package-variant" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.private.packages')}</span></button>
                                        <ul className="collapse">
                                            <li><Link to={'/ustvari-paket'} >{t('leftSide.private.createPackage')}</Link></li>
                                            <li><Link to={'/paketi'} >{t('leftSide.private.packageList')}</Link></li>
                                            <li><Link to={'/paketi/zasebno'} >{t('leftSide.private.getPackage')}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <button onClick={(e) => {handleClick(e,'javne-reference')}} id="javne-reference" className="has-arrow waves-effect waves-dark" aria-expanded="false"><i><span className="iconify" data-icon="mdi-bulletin-board" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.private.publicReferences')}</span></button>
                                        <ul className="collapse">
                                            <li><Link to={'/javne-reference'} >{t('leftSide.private.publicReferences')}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <button onClick={(e) => {handleClick(e,'advancedSearch')}} id="advancedSearch" className="has-arrow waves-effect waves-dark" aria-expanded="false"><i><span className="iconify" data-icon="mdi-layers-search-outline" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.private.advancedSearch')}</span></button>
                                        <ul className="collapse">
                                            <li><Link to={'/napredno-iskanje'} >{t('leftSide.private.advancedSearch')}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <button onClick={(e) => {handleClick(e,'termsConditions')}} id="termsConditions" className="has-arrow waves-effect waves-dark" aria-expanded="false"><i><span className="iconify" data-icon="mdi-layers-search-outline" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.public.termsConditions')}</span></button>
                                        <ul className="collapse">
                                            <li><Link to={'/splosni-pogoji'} >{t('leftSide.public.termsConditions')}</Link></li>
                                        </ul>
                                    </li>
                                </>
                                :
                                <>
                                    <li className="nav-small-cap"></li>
                                    <li>
                                        <button onClick={(e) => {handleClick(e,'osnutek')}} id="osnutek" className="has-arrow waves-effect waves-dark" aria-expanded="false"><i><span className="iconify" data-icon="mdi-book-multiple" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.private.drafts')}</span></button>
                                        <ul className="collapse">
                                            <li><Link to={'/osnutek/ustvari'} >{t('leftSide.private.newDraft')}</Link></li>
                                            <li><Link to={'/moji-osnutki'} >{t('leftSide.private.myDrafts')}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <button onClick={(e) => {handleClick(e,'reference')}} id="reference" className="has-arrow waves-effect waves-dark" aria-expanded="false"><i><span className="iconify" data-icon="mdi-clipboard-outline" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.private.myReferences')}</span></button>
                                        <ul className="collapse">
                                            <li><Link to={'/reference/zasebne'} >{t('leftSide.private.privateReferences')}</Link></li>
                                            <li><Link to={'/reference/javne'} >{t('leftSide.private.publicReferences')}</Link></li>
            
                                        </ul>
                                    </li>
                                    <li>
                                        <button onClick={(e) => {handleClick(e,'packages')}} id="packages" className="has-arrow waves-effect waves-dark" aria-expanded="false"><i><span className="iconify" data-icon="mdi-package-variant" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.private.packages')}</span></button>
                                        <ul className="collapse">
                                            <li><Link to={'/ustvari-paket'} >{t('leftSide.private.createPackage')}</Link></li>
                                            <li><Link to={'/paketi'} >{t('leftSide.private.packageList')}</Link></li>
                                            <li><Link to={'/paketi/zasebno'} >{t('leftSide.private.getPackage')}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <button onClick={(e) => {handleClick(e,'javne-reference')}} id="javne-reference" className="has-arrow waves-effect waves-dark" aria-expanded="false"><i><span className="iconify" data-icon="mdi-bulletin-board" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.private.publicReferences')}</span></button>
                                        <ul className="collapse">
                                            <li><Link to={'/javne-reference'} >{t('leftSide.private.publicReferences')}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <button onClick={(e) => {handleClick(e,'advancedSearch')}} id="advancedSearch" className="has-arrow waves-effect waves-dark" aria-expanded="false"><i><span className="iconify" data-icon="mdi-layers-search-outline" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.private.advancedSearch')}</span></button>
                                        <ul className="collapse">
                                            <li><Link to={'/napredno-iskanje'} >{t('leftSide.private.advancedSearch')}</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <button onClick={(e) => {handleClick(e,'termsConditions')}} id="termsConditions" className="has-arrow waves-effect waves-dark" aria-expanded="false"><i><span className="iconify" data-icon="mdi-routes" data-inline="false"></span></i><span className="hide-menu">{t('leftSide.public.termsConditions')}</span></button>
                                        <ul className="collapse">
                                            <li><Link to={'/splosni-pogoji'} >{t('leftSide.public.termsConditions')}</Link></li>
                                        </ul>
                                    </li>
                                </>
                            )
                        }
                    </ul>
                </nav>        
            </div>
            <div className="sidebar-footer">
                {
                    type === "public" ?
                    <>
                        <Link to={'/login'} className="link" data-toggle="tooltip" title="Prijava"><i className="ti-user"></i></Link>
                        <Link to={'/register'} className="link" data-toggle="tooltip" title="Registracija"><i className="ti-pencil-alt"></i></Link>
                    </>
                    :
                    <>
                        <Link to={"/nakup-kreditov"} className="link" data-toggle="tooltip" title="nakup kreditov"><i className="ti-user"></i></Link>
                        <button onClick={logout} className="link" data-toggle="tooltip" title="odjava"><i className="ti-power-off"></i></button>
                    </>
                }
                <button className="dropdown-toggle u-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="ti-help"></i></button>
                <div className="dropdown-menu animated flipInY helpdeskpopup">
                    <ul style={{listStyle: 'none', paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>
                        <li style={{paddingLeft: 0}}>
                            <b>{t('leftSide.public.helpdesk')}</b><br />
                            {t('leftSide.public.helpdeskText')}    
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
    )
}

export default LeftSide;