import axios from "axios";
import {t} from 'i18next';
import Swal from 'sweetalert2';

import env from '../../../env/src_config'
import { isNull } from "../../../izUtils";
import { setUserData } from '../../../components/slices/userSlice';
import { handleError } from "../../Error/ErrorHandler";

export const getUserData = (userState, dispatch, navigate) => {
    axios.get(env.api.api + '/private/user', userState.options).then(response => {
        if (!isNull(response.data)) {
            localStorage.setItem('EREFERENCE.userData', JSON.stringify(response.data));
            dispatch(setUserData(response.data));
            if (isNull(response.data.data.phone)) {
                Swal.fire({
                    title: t('Dodaj svojo GSM številko'),
                    text: t('Prosimo dodajte telefonsko številko (dostop v primeru izgube TOTP ključa sicer ne bo mogoč)'),
                    icon: "info",
                    confirmButtonColor: '#96B448',
                    confirmButtonText: t('Dodaj zdaj'),
                    showCancelButton: true,
                    cancelButtonText: t('Bom dodal kasneje'),
                }).then(value => {
                    if (value.isConfirmed && !isNull(navigate)) navigate("/profil");
                })
            }
        }
    }, (error) => {
        handleError(error.response.data, navigate);
    });
}