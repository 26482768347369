import React, { Suspense, Fragment, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {t} from 'i18next';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';
import { TailSpin } from "react-loader-spinner"

import { isNull, parseJwt } from "./izUtils";
import { setToken, setUserData } from './components/slices/userSlice';
import { getUserData } from './hoc/Helpers/Functions/getUserData';
import Header from './hoc/Header/Header';
import LeftSide from './hoc/LeftSide/LeftSide';
import Footer from './hoc/Footer/Footer';

const Dashboard = React.lazy(() => import('./components/Dashboard/Dashboard'));
const Login = React.lazy(() => import('./components/Auth/Login'));
const Register = React.lazy(() => import('./components/Auth/Register'));
const Confirm = React.lazy(() => import('./components/Auth/Confirm'));
const PasswordReset = React.lazy(() => import('./components/Auth/PasswordReset'));
const PriceList = React.lazy(() => import('./components/PriceList/PriceList'));
const TermsConditions = React.lazy(() => import('./components/TermsConditions/TermsConditions'));
const AdvancedSearch = React.lazy(() => import('./components/AdvancedSearch/AdvancedSearch'));
const Credits = React.lazy(() => import('./components/Credits/Credits'));
const Help = React.lazy(() => import('./components/Help/Help'));
const CookieConsent = React.lazy(() => import('./hoc/CookieConsent/CookieConsent'));
const CookiesContent = React.lazy(()=> import('./hoc/CookieConsent/CookieContent'));
const Profile = React.lazy(()=> import('./components/Profile/Profile'));

// Public Reference
const PublicReferenceList = React.lazy(() => import('./components/PublicReference/List/PublicList'));
const PublicReferenceSingle = React.lazy(() => import('./components/PublicReference/Single/Single'));

// Private Reference
const ReferenceList = React.lazy(() => import('./components/Reference/List/List'));
const SingleReference = React.lazy(() => import('./components/Reference/Single/Single'));

const CreatePackage = React.lazy(() => import('./components/Reference/Packages/Create/CreatePackage'));
const ListPackages = React.lazy(() => import('./components/Reference/Packages/List/ListPackages'));
const SinglePackages = React.lazy(() => import('./components/Reference/Packages/Single/Single'));

// Osnutek
const NewDraft = React.lazy(() => import('./components/Draft/New/NewDraft'));
const DraftList = React.lazy(() => import('./components/Draft/List/DraftList'));

const App = () => {
    const navigate = useNavigate();
    const userState = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const [cookies, setCookie, removeCookie] = useCookies();

    useEffect(() => {
        getWidth();
        window.addEventListener("resize", getWidth);

        if (!isNull(cookies.token)) {
            const parsedToken = parseJwt(cookies.token);
            if (parsedToken.exp * 1000 < Date.now()) {
                Swal.fire({
                    title: t('logout.title'),
                    html: t('logout.text'),
                    icon: 'error',
                    confirmButtonColor: '#0BCE83',
                    confirmButtonText: t('ok')
                });
                logout();
            } else {
                dispatch(setToken(cookies.token));
            }

        } else {
            dispatch(setToken(null));
        }

        return () => window.removeEventListener("resize", getWidth)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const userDataLS = JSON.parse(localStorage.getItem('EREFERENCE.userData'));
        if (isNull(userDataLS)) {
            if (!isNull(userState.parsedToken) && userState.parsedToken.length !== 0) {
                // if (!userState.parsedToken.terms) navigate('/splosni-pogoji'); // Check for terms in token
                getUserData(userState, dispatch, navigate);
            } else if (!isNull(userState.userData)) {
                dispatch(setUserData(null));
            }
        } else {
            if (isNull(userState.userData)) dispatch(setUserData(userDataLS));
        }

    }, [userState.parsedToken]) // eslint-disable-line

    const getWidth = () => {
        const width = window.innerWidth;
        if(width < 767) {
            document.body.classList.add("mini-sidebar");
            document.body.classList.remove("show-sidebar");
        } else if (width < 1170 && width >= 767) {
            document.body.classList.add("mini-sidebar", "show-sidebar");
        } else {
            document.body.classList.remove("mini-sidebar");
            document.body.classList.add("show-sidebar");
        }
    }

    const handleToken = (data) => {
        let token = null;
        token = data.token
        setCookie('token', token, {
            path: '/',
            maxAge: 3600*12 // Will expire after 12hr (value is in number of sec.)
        });
        dispatch(setToken(token));
    }

    const logout = () => {
        localStorage.removeItem('EREFERENCE.packageTokensSelected');
        localStorage.removeItem('EREFERENCE.userData');
        localStorage.removeItem('EREFERENCE.loginInputs');
        localStorage.removeItem('EREFERENCE.registerInputs');
        removeCookie('token', { path: '/' });
        dispatch(setToken(null));
    }

    let loadingScreen = <ReactLoading className='loadingScreen' type={'cylon'} color={"#96B448"} height={'40vw'} width={'40vw'} />
    let options = userState.options;

    if (isNull(userState.parsedToken)) {
        return "Loading";
    } else {
        let type = null;
        if(userState.parsedToken === '')  {
            type = 'public';
        } else {
            type = 'private';
        }

        return (
            <Fragment>
                <div id="preloader" className='preloader justify-content-center align-items-center'>
                    <TailSpin color="#96B448" height={100} width={100} />
                </div>
                <CookieConsent />
                <Header type={type} logout={logout} />
                <LeftSide type={type} logout={logout} />
                <div className="page-wrapper" style={{ minHeight: 'calc(100vh - 62px)' }}>
                    {userState.parsedToken === '' ? 
                        <Routes>
                            <Route path="/" element={<Suspense fallback={loadingScreen}><Dashboard /></Suspense>}/>
                            {/* References */}
                            <Route path="/javne-reference" element={<Suspense fallback={loadingScreen}><PublicReferenceList options={options} /></Suspense>} />
                            <Route path="/javne-reference/:referenceId" element={<Suspense fallback={loadingScreen}><PublicReferenceSingle options={options} /></Suspense>}/>
                            <Route path="/reference/:listType/:referenceId/:packageToken?" element={<Suspense fallback={loadingScreen}><SingleReference options={options} /></Suspense>}/>
                            {/* Packages */}
                            <Route path="/paketi" element={<Suspense fallback={loadingScreen}><ListPackages options={options} /></Suspense>}/>
                            <Route path="/paketi/javno/:packageId?" element={<Suspense fallback={loadingScreen}><SinglePackages /></Suspense>}/>
                            {/* Auth */}
                            <Route path="/login/:type?" element={<Suspense fallback={loadingScreen}><Login handleToken={handleToken} options={options} /></Suspense>}/>
                            <Route path="/register/:type?" element={<Suspense fallback={loadingScreen}><Register /></Suspense>}/>
                            <Route path="/confirm/:token?" element={<Suspense fallback={loadingScreen}><Confirm options={options} /></Suspense>}/>
                            <Route path="/password-reset/:token?" element={<Suspense fallback={loadingScreen}><PasswordReset options={options} /></Suspense>}/>
                            {/* Other */}
                            <Route path="/napredno-iskanje/javno" element={<Suspense fallback={loadingScreen}><AdvancedSearch type={type} /></Suspense>}/>
                            <Route path="/splosni-pogoji" element={<Suspense fallback={loadingScreen}><TermsConditions handleToken={handleToken} /></Suspense>}/>
                            <Route path="/cenik" element={<Suspense fallback={loadingScreen}><PriceList /></Suspense>}/>
                            <Route path="/help" element={ <Suspense fallback={loadingScreen}><Help options={options} /></Suspense>}/>
                            <Route path="/cookies" element={<Suspense fallback={loadingScreen}><CookiesContent /></Suspense>}/>
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                        :
                        <Routes>
                            <Route path="/" element={<Suspense fallback={loadingScreen}><Dashboard options={options} /></Suspense>}/>
                            {/* Drafts */}
                            <Route path="/moji-osnutki" element={<Suspense fallback={loadingScreen}><DraftList options={options} /></Suspense>}/>
                            <Route path="/osnutek/:methodId/:draftId?" element={<Suspense fallback={loadingScreen}><NewDraft /></Suspense>}/>
                            {/* References */}
                            <Route path="/reference/:listType" element={<Suspense fallback={loadingScreen}><ReferenceList /></Suspense>}/>
                            <Route path="/reference/:listType/:referenceId/:packageToken?" element={<Suspense fallback={loadingScreen}><SingleReference /></Suspense>}/>
                            <Route path="/javne-reference" element={<Suspense fallback={loadingScreen}><PublicReferenceList /> </Suspense>}/>
                            <Route path="/javne-reference/:referenceId" element={<Suspense fallback={loadingScreen}><PublicReferenceSingle /></Suspense>}/>
                            {/* Packages */}
                            <Route path="/ustvari-paket/:packageId?" element={<Suspense fallback={loadingScreen}><CreatePackage /></Suspense>}/>
                            <Route path="/paketi" element={<Suspense fallback={loadingScreen}><ListPackages /></Suspense>}/>
                            <Route path="/paketi/:type/:packageId?" element={<Suspense fallback={loadingScreen}><SinglePackages /></Suspense>}/>
                            {/* Other */}
                            <Route path="/napredno-iskanje" element={<Suspense fallback={loadingScreen}><AdvancedSearch type={type} /></Suspense>}/>
                            <Route path="/splosni-pogoji" element={<Suspense fallback={loadingScreen}><TermsConditions handleToken={handleToken} /></Suspense>}/>
                            <Route path="/nakup-kreditov" element={ <Suspense fallback={loadingScreen}><Credits options={options} /></Suspense>}/>
                            <Route path="/help" element={ <Suspense fallback={loadingScreen}><Help options={options} /></Suspense>}/>
                            <Route path="/cookies" element={<Suspense fallback={loadingScreen}><CookiesContent /></Suspense>}/>
                            <Route path="/profil" element={<Suspense fallback={loadingScreen}><Profile /></Suspense>}/>
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    }
                    <Footer />
                </div>
            </Fragment>
        );
    }
}

export default App
