import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {isNull} from "./izUtils";

let lng = 'sl-SI';
const new_lang = localStorage.getItem('EREFERENCE.selectedLanguage');
if (!isNull(new_lang)) lng = new_lang;

const translationSI = require(`./assets/i18n/sl-SI.json`)
const translationGB = require(`./assets/i18n/en-GB.json`)
const translationDE = require(`./assets/i18n/de-DE.json`)

let resources = {
  "sl-SI": {
    translation: translationSI
  },
  "en-GB": {
    translation: translationGB
  },
  "de-DE": {
    translation: translationDE
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: lng,
    fallbackLng: 'sl-SI',
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;