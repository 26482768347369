import { createSlice } from '@reduxjs/toolkit';
import { parseJwt, isNull } from '../../izUtils'; 

const acceptLanguage = isNull(localStorage.getItem('EREFERENCE.selectedLanguage')) ? 'sl-SI' : localStorage.getItem('EREFERENCE.selectedLanguage');

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        parsedToken: null,
        options: {
            headers: {
                "Accept": 'application/json',
                "Accept-Language": acceptLanguage,
            }
        },
        userData: null,
    },
    reducers: {
        setLanguage: (state, action) => {
            state.options.headers['Accept-Language'] = action.payload
        },
        setToken: (state, action) => {
            if (action.payload !== null) {
                state.options.headers['Authorization'] = 'Bearer ' + action.payload;
                state.parsedToken = parseJwt(action.payload);
            } else {
                delete state.options.headers.Authorization;
                state.parsedToken = '';
            }
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setLanguage, setToken, setUserData } = userSlice.actions

export default userSlice.reducer