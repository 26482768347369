import React from 'react'
import izstop from '../../assets/images/izstop-logo.png'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="row">
                <div className="footer-left">
                    &copy; 2011-2021
                    <a href="https://www.praetor.si/" target="_blank" rel="noreferrer">
                        <span className="font-weight-600 "> Praetor d.o.o.</span>
                    </a>
                </div>
                <div className="footer-right">
                    <a href="http://www.izstop.si/" target="_blank" rel="noreferrer">
                        <span className="hide">Izstop d.o.o. </span>
                        <img src={ izstop } alt="Izstop-logo"/>
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
