import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        partnerLoading: false,
    },
    reducers: {
        setPartnerLoading: (state, action) => {
            state.partnerLoading = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setPartnerLoading } = loadingSlice.actions

export default loadingSlice.reducer