import $ from "jquery";

/* datatables */
import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-buttons';
import 'datatables.net-responsive';
import './assets/css/datatables/select.dataTables.min.css';
import './assets/css/datatables/jquery.dataTables.min.css';
import './assets/css/datatables/dataTables.bootstrap4.min.css';
import './assets/css/datatables/datatables-buttons-fix.css';
import './assets/css/datatables/responsive.dataTables.css';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
/* end_of datatables */

export const isNull = (value) => {
    return (value === undefined || value === null || value === 'null')
 };

 export const create_datatable = (t, id, bid = null, columnDefs, order = [ 1, "desc" ]) => {
    let columns = [];
    let action_buttons = -1;

    if ((columnDefs !== undefined) && (columnDefs.targets !== undefined)) {
        action_buttons = columnDefs.targets;
    }

    for (let i = 1; i < $("#" + id + " tr th").length; i++) {
        if (bid) columns.push(i);
        else if (!bid && i !== action_buttons) { //action_button
            columns.push(i);
        }
    }

    let dataTables = [{
        "targets": 0,
        "orderable": false
    }];

    if (columnDefs !== undefined) {
        dataTables.push(columnDefs);
    }

    return $("#" + id).DataTable({
        "responsive": true,
        "language": t('dataTableLanguageSI', {returnObjects: true}),
        "iDisplayLength": 10,
        "dom": 'lfrtip',
        "columnDefs": dataTables,
        "order": [order],
        "buttons":
            [
                { "text": 'ff', /*"className": classes.Icons,*/ }
            ]
    });
}

export const create_datatable_server_side = (t, id, columns, getData, order = [ 1, "desc" ]) => {
    return $("#" + id).DataTable({
        // processing: true,
        serverSide: true,
        ajax: async function (data, callback, settings) {
            const preloader = document.getElementById('preloader');
            preloader.style.display = 'flex';
            let tableData = await getData().then(val => val)
            console.log(tableData)
            preloader.style.display = 'none'
            callback(tableData);
        },
        columns,
        lengthMenu: [[5, 10, 20, 50, 100], [5, 10, 20, 50, 100]],
        responsive: true,
        language: t('dataTableLanguageSIServer', {returnObjects: true}),
        iDisplayLength: 10,
        dom: 'lfrtip',
        // order: order,
        buttons: [
            { text: 'ff', /*"className": classes.Icons,*/ }
        ]
    });
}

export const time_reformat = (dbtime, seconds = false) => {
    const d = new Date(dbtime);

    let t = [d.getDate(), (d.getMonth() + 1), d.getHours(), d.getMinutes(), d.getSeconds()];

    for (let i = 0; i < t.length; i++) {
        if (t[i] < 10) {
            t[i] = '0' + t[i];
        }
    }

    return t[0] + '.' + t[1] + '.' + d.getFullYear() + ', ' + t[2] + ':' + t[3] +(seconds ? ':'+t[4] : '');
};

export const time_reformat_to_array = (dbtime) => {
    const d = new Date(dbtime);

    let t = [d.getDate(), (d.getMonth() + 1), d.getHours(), d.getMinutes(), d.getSeconds()];

    for (let i = 0; i < t.length; i++) {
        if (t[i] < 10) {
            t[i] = '0' + t[i];
        }
    }

    return [t[0] + '.' + t[1] + '.' + d.getFullYear(), t[2] + ':' + t[3] + ':' +t[4]];
};

export const time_reformat_wothout_time = (dbtime) => {
    if (dbtime === '0000-00-00 00:00:00' || dbtime === null) return null;

    const d = new Date(dbtime);
    let t = [d.getDate(), (d.getMonth() + 1), d.getHours(), d.getMinutes(), d.getSeconds()];

    for (let i = 0; i < t.length; i++) {
        if (t[i] < 10) {
            t[i] = '0' + t[i];
        }
    }

    return t[0] + '.' + t[1] + '.' + d.getFullYear();
};

export const number_format = (number, decimals, dec_point, thousands_sep) => {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        toFixedFix = function (n, prec) {
            let k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    let s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const dateFormatForBE = (date, showTime) => {
    if (!isNull(date) && date.length !== 0) {
        const dateTime = date.split('T');
        return  dateTime[0] + (showTime ? ' ' + dateTime[1].replace('Z','') : '');
    } else {
        return null;
    }
}

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};