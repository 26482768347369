import React, { useState, useEffect } from 'react'

const Time = () => {
    const [date, setDate] = useState('')

    useEffect(() => {
        const interval = setInterval(() => {
            const currentdate  = new Date()
            const datetime = ('0' + currentdate.getDate()).slice(-2) + "."
            + ('0' + (currentdate.getMonth()+1)).slice(-2)  + "." 
            + currentdate.getFullYear() + " "  
            + currentdate.getHours() + ":"  
            + ('0' + currentdate.getMinutes()).slice(-2) + ":" 
            + ('0' + currentdate.getSeconds()).slice(-2);
            setDate(datetime)}, 1000);
        return () => {
          clearInterval(interval);
        };
    }, [])

    return (
        <div className="ajax_servertime">{date}</div>
    )
}

export default Time;