import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import {t} from 'i18next';
import Time from './Time'
import LanguageMenu  from './LanguageMenu/LanguageMenu'
import logo from '../../assets/images/ereference-logo.svg'
import { isNull } from '../../izUtils'
import logoSmall from '../../assets/images/ereference-logo-small.svg'

const Header = ({type, logout}) => {
    const userState = useSelector((state) => state.user)
    // const [navOpened, setNavOpened] = useState(false)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 767);
    
    const handleHamburgerMenu = (close) => {
        const ismobile = window.innerWidth < 767;
        let sidebarDisplay = isMobile;

        if (ismobile !== isMobile) {
            setIsMobile(ismobile);
            sidebarDisplay = ismobile
        }
        
        if(sidebarDisplay) {
            document.body.classList.add("mini-sidebar");
            document.body.classList.toggle("show-sidebar");
        } else {
            document.body.classList.toggle("mini-sidebar");
            document.body.classList.add("show-sidebar");
        }
        
        // if(close) {
        //     setNavOpened(!navOpened)
        // }
    }

    const userData = userState.parsedToken;

    let langIcon = 'si'
    if (!isNull(localStorage.getItem('EREFERENCE.selectedLanguage'))) {
        langIcon = localStorage.getItem('EREFERENCE.selectedLanguage').split('-')[1].toLowerCase()
    }

    return (
        <div id="main-wrapper">
            <header className="topbar">
                <nav className="navbar top-navbar navbar-expand-md navbar-light">

                    <div className="navbar-header">
                        <Link className="navbar-brand" to="/">
                            <img src={ logo } alt="homepage" className="dark-logo svg-logo logo-big" />
                            <img src={ logoSmall } alt="homepage" className="dark-logo svg-logo logo-small" />
                        </Link>
                    </div>

                    <div className="navbar-collapse">
                        <ul className="navbar-nav mr-auto mt-md-0">
                            <li className="nav-item">
                                <button onClick={() => {handleHamburgerMenu(true)}} className="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark"><i><span id="close-sidebar" className="iconify" data-icon="mdi-menu" data-inline="false"></span></i></button>
                            </li>
                            <li className="nav-item m-l-10">
                                <button onClick={() => {handleHamburgerMenu(false)}} className="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark"><i className="ti-menu"></i></button>
                            </li>
                        </ul>
                        <ul className="navbar-nav my-lg-0">
                            <li className="nav-item">
                                <Time/>
                            </li>
                            <li className="nav-item dropdown">
                                <button className="nav-link dropdown-toggle text-muted waves-effect waves-dark" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className={`flag-icon flag-icon-${langIcon}`}></i></button>
                                <LanguageMenu />
                            </li>
                            {
                                type === "private" ?
                                <li className="nav-item dropdown">
                                    <button className="nav-link dropdown-toggle waves-effect waves-dark" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="ti-user"></i>     
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right scale-up">
                                        <ul className="dropdown-user">
                                            <li>
                                                <div className="dw-user-box">
                                                    <div className="u-text">
                                                        <h4 className="color-darker font-weight-600">{userData.name}</h4>
                                                        <p className="text-muted">{userData.email}</p>
                                                        <p>&nbsp;</p>
                                                        <p className="color-darker">{t('header.user.lastLogin')}</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li role="separator" className="divider"></li>
                                            <li>
                                                <Link to={"/profil"}><i className="ti-user"></i> {t('header.user.profile')}</Link>
                                            </li>
                                            <li>
                                                <Link to={"/nakup-kreditov"}><i className="ti-notepad"></i> {t('header.user.creditPurchase')}</Link>
                                            </li>
                                            <li role="separator" className="divider"></li>
                                            <li>
                                                <button onClick={logout}><i className="ti-power-off"></i> {t('header.user.logout')}</button>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                :
                                <div></div>
                            }
                        </ul>
                    </div>
                </nav>
            </header>
        </div>
    );
}

export default Header;
