import React from 'react'
import { useDispatch } from 'react-redux'
import { setLanguage } from '../../../components/slices/userSlice';
import i18n from '../../../i18n';

const LanguageMenu = () => {
    const dispatch = useDispatch();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('EREFERENCE.selectedLanguage', lang);
        dispatch(setLanguage(lang));
    }

    const flags = [
        {id: 'sl-SI', title: 'SI', class: 'flag-icon flag-icon-si'},
        {id: 'en-GB', title: 'EN', class: 'flag-icon flag-icon-gb'},
        {id: 'de-DE', title: 'DE', class: 'flag-icon flag-icon-de'}
    ];

    const buttons = flags.map(flag => {
        return <button key={flag.id} className="dropdown-item" onClick={() => changeLanguage(flag.id)} ><i className={flag.class}></i> {flag.title}</button>;
    });

    return (
        <div className="dropdown-menu dropdown-menu-right scale-up">
            {buttons}
        </div>
    );
}

export default LanguageMenu