import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter  } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import './i18n';

import store from './store'
import {Provider} from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<div>Loading language...</div>}>
    {/* <React.StrictMode> */}
      <Provider store={store}>
        <CookiesProvider>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </CookiesProvider>
      </Provider>
    {/* </React.StrictMode> */}
  </Suspense>
);