import { isNull } from '../../izUtils';
import Swal from 'sweetalert2';

export const handleError = (error, navigate) => {
    console.log("error:",error)
    
    if (error.error === 'general_terms' && !isNull(navigate) ) {
        navigate("/splosni-pogoji")
    }

    Swal.fire({
        text: error.message,
        icon: "error",
        confirmButtonColor: '#96B448',
        confirmButtonText: 'OK'
    })
}